import React, {useState, FormEvent, ChangeEvent, useContext} from 'react';
import MountainComponent from "../components/MountainComponent";
import FormText from "../components/FormText";
import {Button} from "react-bootstrap";
import NavBarComponent from "../components/NavBarComponent";
import {API_LOGIN} from "../globalConfig";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {LoadingContext} from "../services/LoadingService";
import {ToastContext} from "../services/ToastService";

interface FormData {
    username: string;
    password: string;
}

const LoginPage = () => {
    const navigate = useNavigate();
    const { startLoading, stopLoading } = useContext(LoadingContext)!;
    const { showToast } = useContext(ToastContext)!;

    const [formData, setFormData] = useState<FormData>({
            username: '',
            password: ''
        });

        const handlehandleLoginSubmitInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        };

        const handleLoginSubmit = async (e: FormEvent) => {
            startLoading();
            e.preventDefault();
            const basicAuth = 'Basic ' + btoa(formData.username + ':' + formData.password);
            try {
                const response = await axios.post(API_LOGIN, {}, {
                    headers: {
                        'Authorization': basicAuth,
                        'Content-Type': 'application/json'
                    },
                })
                stopLoading();
                if (response.data.Token) {
                    localStorage.setItem('authToken', response.data.Token);
                    localStorage.setItem('Username', response.data.Username ? response.data.Username : "");
                    window.location.href = "/home";
                } else {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('Username');
                    showToast("danger", "Errore", "Token Non Valido", 3000);
                }
            } catch (err: any) {
                console.log('error', err.message);
                stopLoading();
                showToast("danger", "Errore", err.message, 3000);
            }

        };

    return(<>
        <NavBarComponent></NavBarComponent>
        <MountainComponent></MountainComponent>

        <div className="bg-grey px-4" style={{marginTop: '-80px'}}>
            <h1 className="text-center">Accedi</h1>

            <p className="text-center mb-5">
                Inserisci le credenziali per accedere
                nella tua area riservata.
            </p>

            <form onSubmit={handleLoginSubmit}>
                <FormText type="text" label="Username" value={formData.username} name="username" onChange={handlehandleLoginSubmitInputChange}></FormText>
                <div className="mt-3">
                    <FormText type="password" label="Password" value={formData.password} name="password" onChange={handlehandleLoginSubmitInputChange}></FormText>
                </div>
                <Button className="w-100 mt-5" type="submit">ACCEDI</Button>
            </form>
        </div>

    </>);
};

export default LoginPage;
