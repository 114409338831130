import React, { createContext, useState, ReactNode } from 'react';

interface LoadingService {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const LoadingContext = createContext<LoadingService | null>(null);

interface LoadingProviderProps {
  children: ReactNode;
}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const loadingService: LoadingService = {
    isLoading,
    startLoading,
    stopLoading,
  };

  return (
    <LoadingContext.Provider value={loadingService}>
        {isLoading && <div className="container-loading">
          <div className="spinner"></div>
        </div>}

      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
