import React, {useEffect, useState} from 'react';
import MountainComponent from "../components/MountainComponent";
import {Button} from "react-bootstrap";
import NavBarComponent from "../components/NavBarComponent";
const UnauthorizedPage = () => {

    const[isPWA, setIsPWA] = useState<boolean>(false);

    useEffect(() => {
        if ((window as any).matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone === true) {
            setIsPWA(true);
            console.log('L\'app è installata come PWA');
        } else {
            setIsPWA(false);
            console.log('L\'app non è installata come PWA');
        }
    }, []);
    return(<>
        <NavBarComponent></NavBarComponent>
        <MountainComponent></MountainComponent>

        <div className="bg-grey px-4" style={{marginTop: '-80px'}}>
            <h1 className="text-center">INFOPASS SKIPASS LOMBARDIA</h1>

            <p className="text-center mt-3 mb-5">
                Il pass consultato è un prodotto stagionale per le stazioni della regione Lombardia.
                <br/><br/>
                Per visualizzare i prodotti Skipass Lombardia accedi al portale.
            </p>

            <Button className="w-100" href="https://skipasslombardia.it/" target="_blank">VAI AL SITO</Button>

            <p className="text-center my-5">

                {isPWA && <div> Clicca <a href="/anefOperatorSecretAuthLogin">qui</a> per l'accesso operatori delle stazioni ANEFSKI Lombardia.</div>}
            </p>
        </div>
    </>);
};

export default UnauthorizedPage;
