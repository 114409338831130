import Form from 'react-bootstrap/Form';
import {FormGroup} from "react-bootstrap";
import React from "react";

interface Props {
    name?: string;
    type: string;
    label: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    note?: string;
    onChange?(event?: any): void;
}
export default function FormText(props: Props ) {
    return (
        <FormGroup>
            <Form.Label className="label">
                {props.label}
            </Form.Label>
            <Form.Control type={props.type} disabled={props.disabled} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange}/>
            { props.note && <Form.Text muted>{props.note}</Form.Text>}
        </FormGroup>
    );
}
