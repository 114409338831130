import React, {useEffect} from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './App.scss';
import HomePage from "./pages/HomePage";
import DetailPage from "./pages/DetailPage";
import LoginPage from "./pages/LoginPage";
import ScanPage from "./pages/ScanPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";

export const isAuthenticated = (): boolean => {
    const token = localStorage.getItem('authToken');

    return !!token;
};

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/anefOperatorSecretAuthLogin" element={<LoginPage />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
                <Route path="/home" element={isAuthenticated() ? <HomePage /> : <Navigate to="/unauthorized" />} />
                <Route path="/webCam" element={isAuthenticated() ? <ScanPage /> : <Navigate to="/unauthorized" />} />
                <Route path="/infoPass/:id" element={isAuthenticated() ? <DetailPage /> : <Navigate to="/unauthorized" />} />
                <Route path="/:id" element={isAuthenticated() ? <DetailPage /> : <Navigate to="/unauthorized" />} />
                <Route path="*" element={<UnauthorizedPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
