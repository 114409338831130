import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {ToastProvider} from "./services/ToastService";
import {LoadingProvider} from "./services/LoadingService";
import invisibleFarmLogo from "../src/assets/image/invisiblefarm-logo.png";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ToastProvider>
        <LoadingProvider>
            <StrictMode>

                <div className="w-100 d-flex flex-column" style={{minHeight: "100vh"}}>
                    <div className="flex-fill">
                        <App />
                    </div>

                    <div className="w-100 text-center text-white p-2 mt-3" style={{background: "#0172a7"}}>
                        <span style={{fontSize: "12px"}}>Powered by</span>
                        <img src={invisibleFarmLogo} alt="logo invisible farm" style={{width: "120px", marginLeft: "5px"}}/>
                    </div>
                </div>

            </StrictMode>
        </LoadingProvider>
    </ToastProvider>
);
