import React, {useRef, useEffect, useState} from 'react';
import QrScanner from 'qr-scanner';
import {useNavigate} from "react-router-dom";

const QRScannerComponent: React.FC = () => {
    const navigate = useNavigate();
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const [errors, setErrors] = useState<{ chipId?: string }>({});

    const [acceptedPhoto, setAcceptedPhoto] = useState<boolean>(false)

    useEffect(() => {

        const scanner = new QrScanner(videoRef.current!, result => {

            // Validazione
            let hasError = false;
            const newErrors: { chipId?: string } = {};

            const lastIndex = result.lastIndexOf('/');
            const chipId = result.substring(lastIndex + 1);

            const regexPattern = /^\d{20}-\d{3}$/;

            if (!chipId) {
                newErrors.chipId = "Il campo ChipId è obbligatorio.";
                hasError = true;
            } else if (!regexPattern.test(chipId)) {
                newErrors.chipId = "Il formato del ChipId non è valido. Deve essere di 20 numeri seguito da '-' e poi 3 numeri.";
                hasError = true;
            }

            if (hasError) {
                setErrors(newErrors);
                return;
            }

            navigate('/infoPass/' + chipId);
        });

        scanner.start();

        // Cleanup
        return () => {
            scanner.stop();
        };
    }, []);

    return (
        <div>
            <div style={{height: "40vh", position: "relative"}}>
                <div className="qrcode-area"></div>
                <video ref={videoRef} playsInline style={{ width: '100%', height: "100%", objectFit: "cover" }}></video>
            </div>

            {errors && <div className="text-red fs-6 my-3 text-center">{errors.chipId}</div>}
        </div>
    );
};

export default QRScannerComponent;
