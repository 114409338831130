import React from 'react';
import mountain from "../assets/image/mountain.png";
const MountainComponent = () => {
    return(<>
        <div style={{height: '200px', marginTop: '80px'}} className="bg-white">
            <img src={mountain} alt="logo skipass lombardia" className="w-100 h-100"/>
        </div>
    </>);
};

export default MountainComponent;
