import React, { createContext, useState, ReactNode } from 'react';
import Toast from 'react-bootstrap/Toast';
import {ToastContainer} from "react-bootstrap";
interface ToastService {
    show: boolean;
    showToast: (type: 'danger' | 'warning' | 'success', title: string, message: string, time?: number) => void;
    time?: number;
}

const ToastContext = createContext<ToastService | null>(null);

interface ToastProviderProps {
    children: ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {

    const [show, setShow] = useState(false);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const toggleToast = () => setShow(!show);

    const showToast = (type: string, title: string, message: string, time?: number) => {
        setShow(true);
        setType(type);
        setTitle(title);
        setMessage(message);
        setTimeout(() => (setShow(false)), time || 3000);
    };

    const ToastService: ToastService = {
        show,
        showToast
    };

    return (
        <ToastContext.Provider value={ToastService}>
            {children}

            <ToastContainer position="top-end" className="mt-5 me-4 toast-container">
                <Toast show={show} onClose={toggleToast} typeof={type}>
                    <Toast.Header>
                        <div className={ "pointer " + type }></div>
                        <strong className="me-auto">{ title }</strong>
                    </Toast.Header>
                    <Toast.Body>{ message }</Toast.Body>
                </Toast>
            </ToastContainer>

        </ToastContext.Provider>
    );
};

export { ToastContext, ToastProvider };
