import React, {ChangeEvent, FormEvent, useState} from 'react';
import MountainComponent from "../components/MountainComponent";
import FormText from "../components/FormText";
import {Button} from "react-bootstrap";
import NavBarComponent from "../components/NavBarComponent";
import {useNavigate} from "react-router-dom";

interface FormData {
    chipId: string;
}

const HomePage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>({
        chipId: '',
    });
    const [errors, setErrors] = useState<{ chipId?: string }>({});
    const nameUser = localStorage.getItem('Username');

    const handlehandleChipIdSubmitInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleChipIdSubmit = (e: FormEvent) => {
        e.preventDefault();

        // Validazione
        let hasError = false;
        const newErrors: { chipId?: string } = {};

        const regexPattern = /^\d{20}-\d{3}$/;

        if (!formData.chipId) {
            newErrors.chipId = "Il campo ChipId è obbligatorio.";
            hasError = true;
        } else if (!regexPattern.test(formData.chipId)) {
            newErrors.chipId = "Il formato del ChipId non è valido. Deve essere di 20 numeri seguito da '-' e poi 3 numeri.";
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        navigate("/infoPass/" + formData.chipId);
    };

    return(<>
        <NavBarComponent></NavBarComponent>
        <MountainComponent></MountainComponent>

        <div className="bg-grey px-4" style={{marginTop: '-80px'}}>
            <h1 className="text-center">Buongiorno</h1>

            <p className="text-center mb-5">
                Ciao { nameUser }, inserisci il codice tella tessera che vuoi cercare.
            </p>

            <form onSubmit={handleChipIdSubmit}>
                <FormText placeholder="00000000000000000000 - 000" type="text" label="Inserisci il ChipId" value={formData.chipId} name="chipId" onChange={handlehandleChipIdSubmitInputChange}></FormText>
                <Button className="w-100 mt-5" type="submit">CERCA</Button>
            </form>

            {errors && <div className="text-red fs-6 my-3 text-center">{errors.chipId}</div>}

            <div className="d-flex flex-row align-items-center">
                <hr className="w-100"/>
                <div className="text-center p-4">o</div>
                <hr className="w-100"/>
            </div>

            <Button className="w-100" href="/webCam">SCANSIONA</Button>
        </div>

    </>);
};

export default HomePage;
