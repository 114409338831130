import React, {useContext, useEffect, useState} from 'react';
import backgroundImage from "../assets/image/ski.jpg";
import MountainComponent from "../components/MountainComponent";
import {MdArrowBack, MdBlockFlipped, MdCheck, MdPerson, MdWarningAmber} from "react-icons/md";
import {useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import {API_INFO_TESSERA} from "../globalConfig";
import {ToastContext} from "../services/ToastService";
import {LoadingContext} from "../services/LoadingService";

type InfoPassResponse = {
    data: {
        ResponseCode: number,
        Message: string,
        InfoPass: InfoPass
    };
    status: number;
    statusText: string;
    headers: any;
    config: any;
};
interface InfoPass {
    ChipId: string;
    Foto: string;
    SerialNumber: string;
    Cognome: string;
    Nome: string;
    DataNascita: string;
    TipoPass: string;
    Stato: string;
    StatoAssicurazione: string;
    StatoCarta: number;
}


const DetailPage = () => {
    const [infoPass, setInfoPass] = useState<InfoPass>()
    const token = localStorage.getItem('authToken');
    const { id } = useParams();
    const navigate = useNavigate();

    const { startLoading, stopLoading } = useContext(LoadingContext)!;
    const { showToast } = useContext(ToastContext)!;

    const goBack = () => {
        navigate(-1);
    };

    const getPassInfoById = (id: string) => {
        axios.get(API_INFO_TESSERA + id, {
            headers: {
                'Authorization': `JWT ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response: InfoPassResponse) => {
            setInfoPass(response.data.InfoPass);
            stopLoading();
        }).catch(error => {
            stopLoading();
            showToast("danger", "Errore", error.response.data, 3000);
            navigate("/home");
        });
    }

    useEffect(() => {
        startLoading();
        if (id) {
            const regexPattern = /^\d{20}-\d{3}$/;
            if (!regexPattern.test(id)) {
                showToast("danger", "Errore", "Il formato del ChipId non è valido. Deve essere di 20 numeri seguito da '-' e poi 3 numeri.", 3000);
                navigate("/home");
                stopLoading();
            } else {
                getPassInfoById(id);
            }
        } else {
            stopLoading();
            showToast("danger", "Errore", "Non è stato trovato nessun ID", 3000);
            navigate("/unauthorized");
        }
    }, [])

    return(<>
        {infoPass && <>
            <div className="w-100 d-flex" style={{height: "300px"}}>
                <div className="position-absolute m-4 button-circle bg-white" onClick={goBack}>
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center fs-5">
                        <MdArrowBack />
                    </div>
                </div>
                <img src={backgroundImage} alt="logo skipass lombardia" className="w-100 h-100 object-fit-cover"/>
            </div>

            <div className="w-100 px-4 pb-4 rounded-4 rounded-bottom-0 bg-white position-absolute" style={{ marginTop: "-100px"}}>

                <div className="w-100 d-flex justify-content-center">
                    <div className="avatar-image">
                        {!infoPass.Foto && <div className="icon d-flex align-items-center justify-content-center">
                            <MdPerson />
                        </div>}
                        {infoPass.Foto && <img src={infoPass.Foto} alt="logo skipass lombardia"
                              className="w-100 h-100 object-fit-cover"/>}
                    </div>
                </div>

                <div className="position-relative w-100 d-flex justify-content-center" style={{marginTop: "-40px"}}>
                    <div style={{width: "150px", position: "relative", marginBottom: "40px"}}>
                        <div className={`button-circle status ${infoPass.StatoCarta == 0 ? "active" : ""} ${infoPass.StatoCarta == 1 ? "attention" : ""} ${infoPass.StatoCarta == 2 ? "deactivated" : ""}`}>
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center fs-5">
                                {infoPass.StatoCarta == 0 &&  <MdCheck/>}
                                {infoPass.StatoCarta == 1 &&  <MdWarningAmber/>}
                                {infoPass.StatoCarta == 2 &&  <MdBlockFlipped/>}
                            </div>
                        </div>
                    </div>
                </div>

                {infoPass.TipoPass && <div className="text-center my-3">
                    <span className="bg-secondary py-2 px-4 fw-bold text-white text-uppercase">
                        {infoPass.TipoPass}
                    </span>
                </div>}

                <h1 className="text-center text-primary mt-4">{infoPass.Nome}<br/>{infoPass.Cognome}</h1>

                <p className="text-center fw-bold">
                    {infoPass.DataNascita}
                </p>

                <div style={{marginTop: "-80px"}}>
                    <MountainComponent></MountainComponent>
                </div>

                <div className="bg-grey text-center pb-3" style={{marginTop: "-100px"}}>
                    <div className="py-2">
                        <div>Stato</div>
                        <div className="fw-bold">
                            {infoPass.Stato}
                        </div>
                    </div>
                    <div className="py-2">
                        <div>Assicurazione SNOWCARE</div>
                        <div className="fw-bold">
                            {infoPass.StatoAssicurazione}
                        </div>
                    </div>
                    <div className="py-2">
                        <div>Codice ChipId</div>
                        <div className="fw-bold">
                            {infoPass.ChipId}
                        </div>
                    </div>
                    {infoPass.SerialNumber && <div className="py-2">
                        <div>Codice seriale</div>
                        <div className="fw-bold">
                            {infoPass.SerialNumber}
                        </div>
                    </div>}
                </div>

            </div>
        </>}

    </>);
};

export default DetailPage;
