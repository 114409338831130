import React from "react";
import logo from "../assets/image/logo-skipass-info-pass.png";
const NavBarComponent = () => {
    return (
        <div className="d-flex justify-content-center align-items-center fixed-top bg-white">
            <img src={logo} style={{height: '80px'}} alt="logo skipass lombardia" className="p-2"/>
        </div>
    );
}

export default NavBarComponent;
