import React, { useContext, useEffect, useState } from 'react';
import NavBarComponent from "../components/NavBarComponent";
import MountainComponent from "../components/MountainComponent";
import QRScannerComponent from "../components/QRScannerComponent";
import { LoadingContext } from "../services/LoadingService";
import { ToastContext } from "../services/ToastService";

const ScanPage: React.FC = () => {
    const [blockPermission, setBlockPermission] = useState<boolean>(false);
    const { startLoading, stopLoading } = useContext(LoadingContext)!;
    const { showToast } = useContext(ToastContext)!;

    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const handleCameraPermissionChange = async () => {
        const status = await navigator.permissions.query({name: "camera"} as any);
        const handleChange = () => {
            if (status.state === "granted") {
                setBlockPermission(false);
                stopLoading();
            } else {
                setBlockPermission(true);
                stopLoading();
                showToast("danger", "Errore", "Non hai accesso alla fotocamera o hai negato l'accesso", 3000);
            }
        };

        handleChange();
        status.onchange = handleChange;
    };


    useEffect(() => {
        startLoading();

        if (isMobile()) {
            handleCameraPermissionChange();

            return () => {
                if (navigator.permissions) {
                    navigator.permissions.query({name: "camera"} as any).then(status => {
                        status.onchange = null;
                    });
                }
            };
        } else {

            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    setBlockPermission(false);
                    stopLoading();
                })
                .catch(error => {
                    setBlockPermission(true);
                    stopLoading();
                    showToast("danger", "Errore", "Per accedere a questa sezione devi abilitare i permessi per l'utilizzo della fotocamera", 3000);
                });


        }


    }, [blockPermission]);

    return (
        <>
            <NavBarComponent />
            <MountainComponent />

            <div className="bg-grey px-4" style={{ marginTop: '-80px' }}>
                <h1 className="text-center">SCANSIONA</h1>

                <p className="text-center mb-5">
                    Scansiona il Qr code che trovi sulla tessera che vuoi cercare.
                </p>

                {!blockPermission && <QRScannerComponent />}
            </div>
        </>
    );
};

export default ScanPage;
